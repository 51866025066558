












import { Component, Vue } from 'vue-property-decorator';

import DataList from '@/shared/resources/components/data-list/DataList.vue';
import ORMCollection from '@/core/bridge/orm/ORMCollection';
import ApiORMQueryBuilderExtended from '@/shared/lib/api/query-builders/ApiORMQueryBuilderExtended';
import OnlineOrdersTable from '@/app/modules/online-orders/components/partials/tables/OnlineOrdersTable.vue';
import OnlineOrderCoreModel from '@/modules/online-orders/models/OnlineOrderCoreModel';

@Component({
  components: {
    OnlineOrdersTable,
    DataList,
  },
})
export default class OnlineOrdersList extends Vue {
  /**
   * Data
   */
  private onlineOrders: ORMCollection = new ORMCollection();
  private errorMessage: string | null = null;

  /**
   * Getters
   */
  private get query(): ApiORMQueryBuilderExtended {
    return OnlineOrderCoreModel
      .apiExt()
      .with('statuses');
  }

  /**
   * Handlers
   */
  private onFetch(onlineOrders: ORMCollection) {
    this.onlineOrders = onlineOrders;
  }
}
