















import { Component, Vue } from 'vue-property-decorator';
import MainHeader from '@/shared/resources/components/MainHeader.vue';
import Alert from '@/shared/resources/components/Alert.vue';
import OnlineOrdersList from '@/app/modules/online-orders/components/OnlineOrdersList.vue';
import GridRow from '@/shared/resources/components/grid/GridRow.vue';
import GridCol from '@/shared/resources/components/grid/GridCol.vue';

@Component({
  components: {
    GridCol,
    GridRow,
    OnlineOrdersList,
    MainHeader,
  },
})
export default class OrdersView extends Vue {
}
